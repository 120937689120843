import React, { Component } from 'react'
import { Button, Icon, Drawer as BaseDrawer, Menu } from 'antd'
import { withRouter } from 'react-router-dom'
import { Auth } from 'aws-amplify'

class Drawer extends Component {
  logout() {
    Auth.signOut();
    window.localStorage.removeItem('rates');
    window.localStorage.removeItem('order');
  }
  render() {
    return (
      <BaseDrawer
        bodyStyle={{ padding: '24px 0' }}
        placement={'left'}
        closable={true}
        visible={this.props.show}
        onClose={() => this.props.onClose() }
      >
        <br/>
        <Menu
          defaultSelectedKeys={[this.props.match.path.replace('/', '')]}
          defaultOpenKeys={[this.props.match.path.replace('/', '')]}
          mode="inline"
          onClick={e => {
            this.props.history.push(`/${e.key}`);
          }}
        >
          <Menu.Item key="terminal">
            <Icon type="screen" />
            <span>Terminal</span>
          </Menu.Item>
          <Menu.Item key="history">
            <Icon type="history" />
            <span>History</span>
          </Menu.Item>
        </Menu>
        <br/>
        <Button icon='exit' block onClick={() => this.logout()}>LOGOUT</Button>
      </BaseDrawer>
    )
  }
}

export default withRouter(Drawer)
