// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:6af1e45f-01d1-432b-b8ed-fa31471dea31",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_nXoN3PAYn",
    "aws_user_pools_web_client_id": "30s46c2vc0rifotq0fv1lh2m4i",
    "oauth": {},
    "aws_content_delivery_bucket": "ldc-virtual-terminal-production",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d3j03wb7d2h71c.cloudfront.net"
};


export default awsmobile;
