import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Layout, Button, Row, Col, Popconfirm } from 'antd'

const { Header } = Layout;

class BackHeader extends Component {
  render() {
    return (
      <Header>
        <Row type="flex" justify="space-between">
          <Col span={2}>
            {this.props.confirm &&
              <Popconfirm placement="bottomLeft" title={this.props.confirm} onConfirm={() => this.props.onBack()} okText="Yes" cancelText="No">
                <Button icon='caret-left' type="primary" />
              </Popconfirm>
            }
            {!this.props.confirm && <Button icon='caret-left' type="primary" onClick={() => this.props.onBack()} />}
          </Col>
          <Col span={6}>
            <div>
              <img style={{ width: "100%" }} alt="Lottery.com" src="https://lottery.com/wp-content/themes/lottery-theme/assets/images/logo-large.webp" />
            </div>
          </Col>
          <Col style={{ color: '#FFF', fontWeight: 'bold', fontSize: '1.5rem', align: 'right'}}>
            {this.props.children || null}
          </Col>
        </Row>
      </Header>
    )
  }
}

export default withRouter(BackHeader)
