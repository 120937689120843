import React, { Component } from 'react';
import { InputNumber, Layout, Button, Row, Card } from 'antd'
import { withRouter } from 'react-router-dom'
import { CanvasToBMP } from '../utils'
import Header from '../components/Header'
import Drawer from '../components/Drawer'

const { Content } = Layout

const defaultSettings = {
  width: 390,
  height: 100
}

class Settings extends Component {
  state = {
    loading: true,
    showDrawer: false,
    settings: {}
  }
  constructor(props) {
    super(props);
    this.testPrint = this.testPrint.bind(this)
    this.toggle = this.toggle.bind(this)
    this.updateSetting = this.updateSetting.bind(this)
  }
  componentDidMount() {
    if ( window.localStorage.getItem('settings')) {
      this.setState({
        settings: JSON.parse(window.localStorage.getItem('settings'))
      });
    } else {
      this.setState({
        settings: defaultSettings
      });
      window.localStorage.setItem('settings', JSON.stringify(defaultSettings))
    }
    this.setState({ loading: false });
  }
  toggle() {
    this.setState({showDrawer: !this.state.showDrawer})
  }
  updateSetting(key, value) {
    this.setState({ settings: { ...this.state.settings, [key]: value }}, () => {
      window.localStorage.setItem('settings', JSON.stringify(value))
    });
  }
  async testPrint() {
    const w = this.state.settings.width;
    const h = this.state.settings.height;
    var test = new OffscreenCanvas(w, h);
    var ctx = test.getContext("2d");
    ctx.strokeStyle = 'black';
    ctx.fillStyle = 'white';
    ctx.strokeRect(0, 0, w, h);
    ctx.fillRect(0, 0, w, h);
    const bmp = CanvasToBMP.toDataURL(test);
    console.log('bmp', w, h, bmp.replace('data:image/bmp;base64,', ''));
    switch (this.props.device.platform) {
      case 'web':
        var preview = document.getElementById("preview"); 
        var prv = preview.getContext("2d");
        prv.strokeStyle = 'black';
        prv.fillStyle = 'white';
        prv.strokeRect(0, 0, w, h);
        prv.fillRect(0, 0, w, h);
      break;
      case 'android':
        window.sunmiInnerPrinter.printOriginalText(`${this.state.settings.width} X ${this.state.settings.height} \n\n\n\n`)
        window.sunmiInnerPrinter.printBitmap(bmp.replace('data:image/bmp;base64,', ''), w, h);
        window.sunmiInnerPrinter.printOriginalText("\n\n\n\n")
      break;
      default:
      break;
    }
    
  }
  render() {
    return (
      <Layout className="layout">
        <Header onToggle={this.toggle} />
        <Content id='view' style={{ overflowX: 'scroll' }}>
          <Card>
            <Row>
              <InputNumber value={this.state.settings.width} onChange={(value) => { this.updateSetting('width', value)}} /> 
              x
              <InputNumber value={this.state.settings.height}  onChange={(value) => { this.updateSetting('height', value)}} />
              &nbsp;<Button onClick={this.testPrint}>Print</Button>
            </Row>
            <Row style={{ align: 'center' }}>
              <canvas width={this.state.settings.width} height={this.state.settings.height}  id="preview"></canvas>
            </Row>
          </Card>
        </Content>
        <Drawer show={this.state.showDrawer} onClose={this.toggle} />
      </Layout>
    );
  }
}

export default withRouter(Settings);