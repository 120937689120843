import React, { Component } from 'react'
import { Auth } from 'aws-amplify'
import { ApolloLink } from 'apollo-link';
import { ApolloClient } from 'apollo-client'
import { setContext } from 'apollo-link-context'
import { HttpLink } from 'apollo-link-http'
import { onError } from "apollo-link-error"
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloProvider } from 'react-apollo'
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import Login from './containers/Login'
import Terminal from './containers/Terminal'
import History from './containers/History'
import Settings from './containers/Settings'

let API_URL;

let DEBUG = window.location.host === 'localhost:3001'
  ? true
    : (window.location.host === 'staging-virtual-terminal.s3-website-us-east-1.amazonaws.com')
      ? true
      : false

const log = (msg) => {
  if (DEBUG) {
    console.error(msg);
  }
}

const errorLink = onError((errors) => {
  const { graphQLErrors, networkError } = errors;
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      log( `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    );
  }
  if (networkError){
    if (networkError.statusCode === 401) {
      Auth.signOut();
    }
    log(`[Network error]: ${networkError}`);
  }
});

const authLink = setContext(async (_, { headers }) => {
  const user = await Auth.currentSession().catch((err) => {
    Auth.signOut();
    log(err.message);
  });
  const token = user.getIdToken().getJwtToken();
  return {
    headers: {
      ...headers,
      'X-API-Key': DEBUG ? 'Rj4S3YOc9K3L9VpvYAAk67GHF8oZnGX35esGKvMV' : 'UUniTSjAVb6fkh6A915i1hDKWGQ1nUm5FL7t1sz1',
      authorization: token ? `Bearer ${token}` : "",
    }
  }
})

const ProtectedRoute = ({client, ...props}) => (
  <Route 
    exact={props.exact} 
    path={props.path}
    render={rProps =>
      props.props.isLoggedIn ? (
        <ApolloProvider client={client}><props.render exact={props.exact} /></ApolloProvider>
      ) : (
        <Redirect
          to={`/?redirect=${props.location.pathname}${
            props.location.search
          }`}
        />
      )
    }
  />
);

class App extends Component {
  state = {
    isLoggedIn: false,
    device: null
  };
  constructor(props) {
    super(props);
    if (props.device.platform === 'web') {
      API_URL = window.location.host === 'localhost:3001'
        ? 'http://localhost:3000/terminal'
        : (window.location.host === 'terminal.sandbox.partner.lottery.com')
          ? 'https://api.sandbox.partner.lottery.com/terminal'
          : 'https://api.partner.lottery.com/terminal'
    }
    if (props.device.platform === 'android') {
      API_URL = 'https://api.sandbox.partner.lottery.com/terminal';
      DEBUG = false
    }
    this.onAuthChange = this.onAuthChange.bind(this);
    this.setClient = this.setClient.bind(this);
    this.setClient()
  }
  setClient(altApiUrl) {
    const httpLink = new HttpLink({
      uri: altApiUrl || API_URL
    })

    this.client = new ApolloClient({
      link: ApolloLink.from([authLink, errorLink, httpLink]),
      cache: new InMemoryCache(),
      defaultOptions: {
        connectToDevTools: DEBUG,
        watchQuery: {
          fetchPolicy: 'network-only',
          errorPolicy: 'ignore',
        },
        query: {
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
        },
      },
    })
  }
  async onAuthChange(event) {
    const isLoggedIn = Boolean(event === 'signedIn');
    this.setState({ isLoggedIn }, async () => {
      if (this.state.isLoggedIn) {
        this.setClient()
      }
    })
  }
  render() {
    if (!this.state.isLoggedIn) return <Login change={(e) => this.onAuthChange(e)} />
    return (
      <Router>
        <Switch>
          <Route exact path="/" render={() => <Redirect to={'/terminal'} /> } />
          <ProtectedRoute
            exact
            path="/terminal"
            render={() => <Terminal device={this.props.device} />}
            props={this.state}
            client={this.client}
          />
          <ProtectedRoute
            exact
            path="/history"
            render={() => <History device={this.props.device} />}
            props={this.state}
            client={this.client}
          />
          <ProtectedRoute
            exact
            path="/settings"
            render={() => <Settings device={this.props.device} />}
            props={this.state}
            client={this.client}
          />
        </Switch>
      </Router>
    );
  }
}

export default App;