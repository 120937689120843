import React, { Component } from 'react'
import { Row, Button } from 'antd'
import { length } from 'ramda'

class Pick extends Component {
  render() {
    return (
      <Row>
        {Boolean(length(this.props.pick)) && this.props.pick.map((num, i) => {
          const type = (i < 5) ? 'secondary' : 'primary';
          return <Button key={`${type}-${num}`} size="large" type={type} shape="circle">{num}</Button>
        })}
        {Boolean(this.props.gameboard) && <React.Fragment>
            <Button style={{ margin: '2px' }} size="large" type='secondary' shape="circle">{this.props.gameboard.numbers[0] || ''}</Button>
            <Button style={{ margin: '2px' }} size="large" type='secondary' shape="circle">{this.props.gameboard.numbers[1] || ''}</Button>
            <Button style={{ margin: '2px' }} size="large" type='secondary' shape="circle">{this.props.gameboard.numbers[2] || ''}</Button>
            <Button style={{ margin: '2px' }} size="large" type='secondary' shape="circle">{this.props.gameboard.numbers[3] || ''}</Button>
            <Button style={{ margin: '2px' }} size="large" type='secondary' shape="circle">{this.props.gameboard.numbers[4] || ''}</Button>
            <Button style={{ margin: '2px' }} size="large" type='primary' shape="circle">{this.props.gameboard.powerball || ''}</Button>
        </React.Fragment>}
      </Row>
    )
  }
}

export default Pick
