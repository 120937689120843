import React, { Component } from 'react'
import { Layout, Button, message } from 'antd'
import Header from './BackHeader'
import { CanvasToBMP } from '../utils'

const { Content } = Layout;

class Receipt extends Component {
  state = {
    printing: false
  }
  altPrint() {
    window.print();
    this.setState({ printing: true })
    message.loading('Printing', 3, this.props.onClose)
  }
  webPrint(image) {
    var win = window.open('' );
    var img = win.document.createElement("img");
    img.src = image;
    win.document.body.appendChild(img);
    img.onload = function(){ 
      win.print();
      setTimeout(() => {
        win.close();
      }, 30000)
    };  
    message.loading('Printing', 3, this.props.onClose);
  }
  droidPrint(image) {
    var img = new Image();
    img.onload = async function() {
      console.log(img);
      const bitmap = await createImageBitmap(img).catch(err => {
        console.error(err);
      });
      console.log('bitmap', bitmap)
      const bitmapString = window.btoa(bitmap);
      console.log('bitmapString', bitmapString);
      window.sunmiInnerPrinter.printBitmap(bitmapString);
      this.setState({ printing: false });
    }
    img.src = image;
  }
  canvasPrint(image) {
    var img = new Image( 
      390,
      1170
    );
    img.onload = async () =>{
      const w = img.width;
      const h = img.height;
      var canvas = new OffscreenCanvas(w, h);
      var ctx = canvas.getContext("2d");
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, w, h);
      ctx.drawImage(img, 0, 0, w, h);
      const bmp = CanvasToBMP.toDataURL(canvas);
      console.log('bmp', w, h, bmp);
      window.sunmiInnerPrinter.printBitmap(bmp.replace('data:image/bmp;base64,', ''), w, h);
      window.sunmiInnerPrinter.printOriginalText("\n\n\n\n")
    };
    img.src = image;    
  }
  async print(image) {
    this.setState({ printing: true });
    switch (this.props.device.platform) {
      case 'web':
        this.webPrint(image);
      break;
      case 'android':
        // this.droidPrint(image);
        this.canvasPrint(image);
      break;
      default:
        this.canvasPrint(image);
      break;
    }
  }
  render() {
    return (
      <Layout className="layout">
        <Header onBack={() => this.props.onClose() } confirm="This ticket hasn't been printed, are you sure?" />
        <Content id='viewWithButton'>
        <Button block className='btn-green no-print' disabled={this.state.printing} onClick={() => this.print(this.props.order.printUrl) } loading={this.state.printing}>PRINT TICKET</Button>
          <img style={{ backgroundColor: '#FFFFFF', verticalAlign: 'middle', marginBottom: '2em' }} className='no-print' width="100%" src={this.props.order.previewUrl} alt="Receipt" />
        </Content>
      </Layout>
    )
  }
}

export default Receipt
