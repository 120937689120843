import React, { Component } from 'react'
import { Layout, Button, Row, Col } from 'antd'

const { Header } = Layout;

class AppHeader extends Component {
  render() {
    return (
      <Header>
        <Row type="flex" justify="space-between">
          <Col span={2}>
            <Button icon='menu' type="primary" onClick={() => this.props.onToggle()} />
          </Col>
          <Col span={6}>
            <div>
              <img style={{ width: "100%" }} alt="Lottery.com" src="https://lottery.com/wp-content/themes/lottery-theme/assets/images/logo-large.webp" />
            </div>
          </Col>
          <Col style={{ color: '#FFF', fontWeight: 'bold', fontSize: '1.5rem', align: 'right'}}>
            {this.props.children || null}
          </Col>
        </Row>
      </Header>
    )
  }
}

export default AppHeader
