import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './utils/serviceWorker';
import { Plugins } from '@capacitor/core';
const { Device } = Plugins;

async function start() {
  const info = await Device.getInfo();
  ReactDOM.render(<App device={info} />, document.getElementById('root'));
}

start();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
