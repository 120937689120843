import React, { Component } from 'react'
import Amplify from 'aws-amplify'
import { Authenticator, SignUp } from 'aws-amplify-react'
import { Row } from 'antd'
import awsmobile from '../aws-exports'
import appTheme from '../theme'

Amplify.configure(awsmobile);

class Login extends Component {
  render() {
    return (
      <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', height: '100vh'}}>
        <Row align="middle" justify="center"><div style={{ width: '300px', height: '150px' }} className='site-logo'>Lottery.com</div></Row>
        <Row align="middle" justify="center">
          <Authenticator 
            hide={[SignUp]}
            theme={appTheme}
            onStateChange={this.props.change}  />
        </Row>
      </div>
    )
  }
}

export default Login