import gql from 'graphql-tag'
export const generateRandomTickets = gql`query ($qty: Int){
  generateRandomTickets(qty:$qty) {
    picks {
      numbers
    }
  }
}`;

export const createOrder = gql`mutation order ($order: CreateOrder!, $receipt: ReceiptDetails){
  order (order: $order, receipt: $receipt){
    id
    qty
    draw
    createdAt
    picks {
      numbers
    }
    printUrl
    previewUrl
  }
}`;

export const rates =  gql`query rates {
  rates {
    pricePerTicket
    default {
      name
      rate
    }
    quotes {
      name
      rate
    }
  }
}`;

export const history = gql`query history ($cursor: Cursor){
  history(cursor: $cursor) {
    limit
    skip
    results {
      id
      amount
      qty
      createdAt
      picks {
        numbers
      }
    }
  }
}`;

export const getReceipt = gql`query receipt ($order: String!, $receipt: ReceiptDetails){
  receipt (order: $order, receipt: $receipt){
    printUrl
  }
}`;