import React, { Component } from 'react'
import { Layout, Button, Col, Row, Card, Modal, Icon, Skeleton, Menu, Dropdown, message, notification } from 'antd'
import { length, update, remove, defaultTo, find, propEq, path } from 'ramda'
import { withApollo } from 'react-apollo'
import { Auth } from 'aws-amplify'
import fx from 'money'
import accounting from 'accounting-js'
import Drawer from '../components/Drawer'
import Gameboard from '../components/Gameboard'
import Pick from '../components/Pick'
import Header from '../components/Header'
import Receipt from '../components/Receipt'
import { generateRandomTickets, createOrder, rates } from './queries'

const { Content } = Layout

const defaultToEmptyArray = defaultTo([]);
const defaultToUSD = defaultTo('USD');

notification.config({
  placement: 'bottomLeft',
})

export class Terminal extends Component {
  defaultGame = {
    numbers: [],
    powerball: null
  };
  state = {
    showDrawer: false,
    currentIndex: false,
    gameboard: this.defaultGame,
    qty: 0,
    picks: [],
    rates: {},
    loadingRates: true
  }
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this)
    this.quickPick = this.quickPick.bind(this)
    this.editPick = this.editPick.bind(this)
    this.addPick = this.addPick.bind(this)
    this.removePick = this.removePick.bind(this)
    this.updatePick = this.updatePick.bind(this)
    this.purchase = this.purchase.bind(this)
    this.selectRate = this.selectRate.bind(this)
    this.clearOrder = this.clearOrder.bind(this)
    this.client = this.props.client;
  }
  async componentDidMount() {
    const rawOrder = window.localStorage.getItem('order')
    if (rawOrder) {
      const order = JSON.parse(rawOrder)
      this.setState({ order })
    }
    const user = await Auth.currentUserInfo();
    this.setState({ user });
    this.setRates();
    this.selectRate(user.attributes['custom:currency']);
  }
  setRates() {
    try {
      if (window.localStorage.getItem('rates')) {
        const rates = JSON.parse(window.localStorage.getItem('rates'));
        this.setupFx(rates.quotes);
        this.setState({ 
          rates, 
          loadingRates: false
        })
      } else {
        this.getRates();
      }
      setInterval(this.getRates, 1000 * 60 * 60); // Update rates once an hour
    } catch (err) {
      console.error(err)
      message.error('Error fetching exchange rates.', 2.5);
    }
  }
  getRates() {
    this.client.query({
      query: rates
    }).then(response => {
      const rates = response.data.rates;
      if (rates) {
        this.setupFx(rates.quotes);
        this.setState({ rates, loadingRates: false }, () => {
          if (!this.state.selectedRate) {
            this.selectRate(this.state.user['customer:currency']);
          }
        })
        window.localStorage.setItem('rates', JSON.stringify(rates))
      }
    })
  }
  selectRate(currency) {
    const rates = [{ name: 'USDUSD', rate: 1 }].concat(defaultToEmptyArray(this.state.rates.quotes));
    const rate = find(propEq('name', `USD${currency}`))(rates);
    this.setState({ selectedRate: rate || this.state.rates.default })
  }
  setupFx(quotes) {
    fx.base = 'USD';
    fx.rates = {};
    quotes.map(({name, rate})=> fx.rates[name.replace('USD', '')] = rate)
    fx.rates = { ...fx.rates };
    this.setState({ fxRates: fx.rates });
  }
  renderTotal(altCurrency) {
    if (!this.state.rates || !this.state.selectedRate) {
      return null;
    }
    try {
      let options;
      const basePrice = this.state.rates.pricePerTicket / 100;
      const currency = altCurrency || defaultToUSD(path('selectedRate.name'.split('.'), this.state)).replace('USD', '');
      let price = basePrice * length(this.state.picks)
      if (price < 0) price = 0;
      if (this.state.currentIndex !== false && length(this.state.picks) === 1) price = 0;
      if (this.state.currentIndex !== false && length(this.state.picks) > 1) price = price - basePrice;
      const total = Math.ceil(fx(price).from('USD').to(currency));
      switch (currency) {
        case 'HTG':
          options = {
            format: "G %v"
          };
          break;
        case 'RON':
          options = {
            format: "%v lei"
          };
          break;
        case 'EUR':
          options = {
            symbol: '€',
            format: "%v %s"
          };
          break;
        case 'GBP':
          options = {
            symbol: '£',
            format: "%s%v"
          };
          break;
        default: 
          options = {
            symbol: '$',
            format: "%s%v"
          };
          break;
      }
      return accounting.formatMoney(total, options);
    } catch (err) {
      console.error(err);
      message.error('Error calculating exchange rate.', 2.5);
    }
  }

  clearOrder() {
    this.setState({ printing: false, order: null,  picks: [], currentIndex: false, gameboard: this.defaultGame })
    window.localStorage.removeItem('order');
  }
  clearRates() {
    window.localStorage.removeItem('rates');
    this.getRates();
  }
  toggle() {
    this.setState({showDrawer: !this.state.showDrawer})
  }
  calcPrice(rate, amount) {
    return amount * rate;
  }
  quickPick(qty) {
    this.setState({ loadingQuickPicks: true, qty })
    this.client.query({
      query: generateRandomTickets, 
      variables: { qty }
    }).then(response => {
      if (response.data.generateRandomTickets) {
        const picks = response.data.generateRandomTickets.picks.map(pick => pick.numbers);
        this.setState({ loadingQuickPicks: false, picks: [...this.state.picks, ...picks], qty: 0 })
      }
      if (response.errors && response.errors.length) {
        if (response.errors[0].message.indexOf('CURRENT_DRAW_SOLD_OUT') !== -1)
        Modal.info({
          title: 'Draw Sold Out',
          content: 'The current draw has sold out. Please wait until the next draw or contact your Lottery.com Partner for more details.'
        });
        this.setState({ loadingQuickPicks: false, qty: 0 })
      }
    })
  }
  editPick(currentIndex = null) {
    if (currentIndex) 
      return this.setState({ currentIndex })

    currentIndex = length(this.state.picks)
    const picks = [...this.state.picks, []]
    this.setState({ currentIndex, picks })
  }
  addPick(pick) {
    this.setState({ 
      picks: update(this.state.currentIndex, pick, this.state.picks),
      currentIndex: false,
      gameboard: this.defaultGame
    })
  }
  cancelPick() {
    this.setState({ 
      picks: remove(this.state.currentIndex, 1, this.state.picks),
      currentIndex: false,
      gameboard: this.defaultGame
    })
  }
  removePick(index) {
    this.setState({ 
      picks: remove(index, 1, this.state.picks),
    })
  }
  updatePick(gameboard) {
    this.setState({ gameboard })
  }
  purchase() {
    this.setState({ purchasing: true });
    this.client.mutate({
      mutation: createOrder, 
      variables: { order: { picks: this.state.picks.map(pick => ({ numbers: pick })) }, receipt: { total: this.renderTotal() }}
    }).then(response => {
      this.setState({ order: response.data.order, purchasing: false })
      window.localStorage.setItem('order', JSON.stringify(response.data.order));
    }).catch(err => {
      this.setState({ purchasing: false })
      console.error(err)
      message.error('Unable to complete purchase.', 2.5)
        .then(() => message.error('An error message will be show for support, you may screenshot it or dismiss it.', 5))
      notification['error']({
        message:`Error (sub: ${this.state.user.username})`,
        description: `${err}`
      })
    })
  }
  render() {
    if (this.state.order) return <Receipt device={this.props.device} order={this.state.order} onClose={this.clearOrder} />
  
    const currencyMenu = (this.state.fxRates)
      ? (<Menu onClick={(e) => this.selectRate(e.key)}>{Object.keys(this.state.fxRates).map(currency => <Menu.Item key={currency}>{currency}</Menu.Item>)}</Menu>)
      : null;

    return (
      <Layout className="layout">
        <Header onToggle={this.toggle}>
          { currencyMenu && 
            (<Dropdown overlay={currencyMenu} trigger={['contextMenu']}><span>{this.renderTotal()}</span></Dropdown>)
          }
        </Header>
        <Content id='viewWithButton'>
          <Card>
            {(!length(this.state.picks) && !this.state.loadingQuickPicks) && <Row>
              + Add tickets using buttons below.
            </Row>}
            {Boolean(length(this.state.picks) && Boolean(this.state.currentIndex === false)) && <Col>
              { this.state.picks.map((pick, i) => <Row type="flex" justify="space-between" style={{ margin: '4px 0', }} key={`pick-${pick.join('')}`}><Pick pick={pick} /><Icon style={{ fontSize: '2rem' }} type="delete" size="large" onClick={() => this.removePick(i)}  /></Row>) }
            </Col>}
            {Boolean(length(this.state.picks) && Boolean(this.state.currentIndex !== false)) && <Row gutter={4}>
              <Pick key={`pick-current`} gameboard={this.state.gameboard} />
            </Row>}
            {this.state.loadingQuickPicks && <Skeleton active title={false} paragraph={{  rows: this.state.qty, width: '100%' }}  />}            
          </Card>
          {Boolean(this.state.currentIndex === false) && 
            <Row style={{ margin: '4px', marginTop: '2rem' }} type="flex" justify="space-around">
              <Col span={7}>
                <Row justify="center" align="middle">
                  <h2 style={{ paddingTop: '4px' }}>Quick Pick:</h2>
                </Row>
              </Col>
              <Col span={3}><Button className='clickable' shape='circle' size="large" type='primary' onClick={() => this.quickPick(1) }>1</Button></Col>
              <Col span={3}><Button className='clickable' shape='circle' size="large" type='primary' onClick={() => this.quickPick(2) }>2</Button></Col>
              <Col span={3}><Button className='clickable' shape='circle' size="large" type='primary' onClick={() => this.quickPick(5) }>5</Button></Col>
            </Row>
          }
          {Boolean(this.state.currentIndex === false) && 
            <Row style={{ margin: '4px', marginTop: '2rem' }}>
              <Button shape='round' type='primary' block 
                style={{ padding: '1rem', height: 'auto' }}
                onClick={() => this.editPick() }>Custom Pick</Button>
            </Row>
          }
          {Boolean(this.state.currentIndex !== false) && 
            <Gameboard onAddPick={(pick) => this.addPick(pick)} onChange={(pick) => this.updatePick(pick)} onCancel={() => this.cancelPick()} /> 
          }
          {Boolean(this.state.currentIndex === false) && 
            <Button block className='btn-green' onClick={() => this.purchase() } loading={this.state.purchasing}>Purchase</Button>
          }
        </Content>
        <Drawer show={this.state.showDrawer} onClose={this.toggle} />
      </Layout>
    )
  }
}

export default withApollo(Terminal)
