import React, { Component } from 'react'
import { Button } from 'antd'

class NumberButton extends Component {
  render() {
    const {disabled, active, children, ...props} = this.props;
    return (disabled) 
      ? active 
          ? <Button {...props} disable="true">{children}</Button> 
          : null
      : <Button {...props}>{children}</Button>
    
  }
}

export default NumberButton
