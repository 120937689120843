import React, { Component } from 'react'
import { Layout, Button, Col, Row, Card, Skeleton, message } from 'antd'
import { sum } from 'ramda'
import { withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import Moment from 'react-moment'
import 'moment-timezone'
import { history, getReceipt } from './queries'
import { CanvasToBMP } from '../utils'
import Drawer from '../components/Drawer'
import Header from '../components/Header'

const { Content } = Layout

export class History extends Component {
  state = {
    loading: true,
    showDrawer: false,
    receipts: [],
    skip: 0,
    limit: 10
  }
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.client = this.props.client;
  }
  componentDidMount() {
   this.getHistory();
  }
  async getHistory() {
    const { data } = await this.client.query({ query: history, variables: { limit: this.state.limit, skip: this.state.skip } });
    this.setState({ receipts: [...this.state.receipts, ...data.history.results], skip: sum([this.state.skip, this.state.limit]), loading: false })
  }
  toggle() {
    this.setState({showDrawer: !this.state.showDrawer})
  }
  webPrint(image) {
    var win = window.open('' );
    var img = win.document.createElement("img");
    img.src = image;
    win.document.body.appendChild(img);
    img.onload = function(){ 
      win.print();
      setTimeout(() => {
        win.close();
      }, 30000)
    };  
    const close = () => {
      this.setState({ printing: false, order: null,  picks: [], currentIndex: false, gameboard: this.defaultGame })
      // Maybe do a print success confirm here?
      window.localStorage.removeItem('order');
    };
    message.loading('Printing', 3, close);
  }
  droidPrint(image) {
    var img = new Image();
    img.onload = async function() {
      console.log(img);
      const bitmap = await createImageBitmap(img).catch(err => {
        console.error(err);
      });
      console.log('bitmap', bitmap)
      const bitmapString = window.btoa(bitmap);
      console.log('bitmapString', bitmapString);
      window.sunmiInnerPrinter.printBitmap(bitmapString);
      this.setState({ printing: false });
    }
    img.src = image;
  }
  canvasPrint(image) {
    var img = new Image( 
      window.localStorage.getItem('width'),
      window.localStorage.getItem('height')
    );
    img.onload = async () =>{
      const w = img.width;
      const h = img.height;
      var canvas = new OffscreenCanvas(w, h);
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, w, h);
      const bmp = CanvasToBMP.toDataURL(canvas);
      console.log('bmp', w, h, bmp);
      await window.sunmiInnerPrinter.printBitmap(bmp.replace('data:image/bmp;base64,', ''), w, h);
    };
    img.src = image;    
  }
  async print(order) {
    this.setState({ printing: true })
    const { data } = await this.client.query({ query: getReceipt, variables: {
      order: order.id,
      receipt: {
        total: "REPRINT"
      }
    }})
    const image = data.receipt.printUrl;
    switch (this.props.device.platform) {
      case 'web':
        this.webPrint(image);
      break;
      case 'android':
        // this.droidPrint(image);
        this.canvasPrint(image);
      break;
      default:
        this.canvasPrint(image);
      break;
    }
  }
  render() {
    return (
      <Layout className="layout">
        <Header onToggle={this.toggle} />
        <Content id='view' style={{ overflowX: 'scroll' }}>
          {this.state.loading && <React.Fragment>
            <Card><Skeleton active title={false} paragraph={{  rows: 2, width: '100%' }}  /></Card>
            <Card><Skeleton active title={false} paragraph={{  rows: 2, width: '100%' }}  /></Card>
            <Card><Skeleton active title={false} paragraph={{  rows: 2, width: '100%' }}  /></Card>
            <Card><Skeleton active title={false} paragraph={{  rows: 2, width: '100%' }}  /></Card>
            <Card><Skeleton active title={false} paragraph={{  rows: 2, width: '100%' }}  /></Card>
            <Card><Skeleton active title={false} paragraph={{  rows: 2, width: '100%' }}  /></Card>
            <Card><Skeleton active title={false} paragraph={{  rows: 2, width: '100%' }}  /></Card>
            <Card><Skeleton active title={false} paragraph={{  rows: 2, width: '100%' }}  /></Card>
            <Card><Skeleton active title={false} paragraph={{  rows: 2, width: '100%' }}  /></Card>
          </React.Fragment>}
          {this.state.receipts.map(order => {
            return (
              <Card key={order.id}>
                <Row type='flex' justify='space-between'>
                  <Col>
                    <Moment format="YYYY/MM/DD HH:mm">{order.createdAt}</Moment>
                  </Col>
                  <Col>
                    <Button onClick={e => this.print(order)}>Reprint</Button>
                  </Col>
                </Row>
              </Card>
            );
          })}
          <canvas id="canvas" style={{ display: 'none' }}></canvas>
        </Content>
        <Drawer show={this.state.showDrawer} onClose={this.toggle} />
      </Layout>
    )
  }
}

export default withApollo(withRouter(History))
