import React, { Component } from 'react'
import { Button, Card } from 'antd'
import NumberButton from './NumberButton'
import { contains, equals, length, uniq, take, takeLast } from 'ramda'

class Gameboard extends Component {
  state = {
    numbers: [],
    powerball: 0,
    locked: false
  }
  constructor(props) {
    super(props);
    this.pickNumber = this.pickNumber.bind(this)
    this.pickPowerball = this.pickPowerball.bind(this)
    this.addPick = this.addPick.bind(this)
    this.cancelPick = this.cancelPick.bind(this)
    if (length(props.pick)) {
      const numbers = take(5, props.pick)
      const powerball = takeLast(1, props.pick)
      this.setState({ numbers, powerball })
    }
  }
  pickNumber(n) {
    if (this.state.locked) return;
    this.setState({ numbers: uniq([...this.state.numbers, n])}, () => {
      if (length(this.state.numbers) > 4) {
        this.setState({ locked: true })
      }
      this.props.onChange(this.state)
    })
  }
  pickPowerball(powerball) {
    this.setState({ powerball }, () => this.props.onChange(this.state))
  }
  addPick() {
    if (this.props.onAddPick) this.props.onAddPick([...this.state.numbers, this.state.powerball])
  }
  cancelPick() {
    if (this.props.onCancel) this.props.onCancel()
  }
  render() {
    return (
      <div style={{ width: '812', margin: 'auto' }}>
        <Card style={{ flex: 1 }} title="Pick 5">
          {new Array(69).fill(1).map((v, i) => 
              <NumberButton key={`white-${i}`} 
                style={{ margin: '2px' }}
                disabled={this.state.locked}
                value={v + i}
                active={(contains(v + i, this.state.numbers))}
                className={`whiteball ${(contains(v + i, this.state.numbers)) ? 'active' : ''}`}
                onClick={(e) => this.pickNumber(Number(e.target.value)) }
                type="secondary" shape="circle" size='large'>{v + i}</NumberButton>
          )}
        </Card>
        <Card title="Pick 1">
          {new Array(26).fill(1).map((v, i) => 
              <NumberButton key={`power-${i}`} 
                style={{ margin: '2px' }}
                disabled={this.state.powerball}
                value={v + i}
                active={(equals(v + i, this.state.powerball))}
                className={`powerball ${(equals(v + i, this.state.powerball))? 'active' : ''}`}
                onClick={(e) => this.pickPowerball(Number(e.target.value)) }
                type="primary" shape="circle" size='large'>{v + i}</NumberButton>
          )}
        </Card>
        <Button 
          type="primary" block size='large' 
          ghost={!(this.state.locked && this.state.powerball)} 
          disable={String(!(this.state.locked && this.state.powerball))}
          onClick={() => this.addPick() }
        >ADD TO PICKS</Button>
        <Button 
          type="secondary" block size='large' 
          onClick={() => this.cancelPick() }
        >CANCEL</Button>
      </div>
    )
  }
}

export default Gameboard
